import {
  type ZoneSegment,
  type ZoneSegmentIntervalType,
  type ZoneSegmentType,
} from '@rouvydev/web-components/utils'
import {
  type WorkoutSegmentFragment,
  WorkoutSegmentIntervalType,
  WorkoutSegmentPrimaryType,
} from '~/model/api'

const workoutSegmentTypeMap = {
  [WorkoutSegmentPrimaryType.Ftp]: 'FTP',
  [WorkoutSegmentPrimaryType.Power]: 'POWER',
  [WorkoutSegmentPrimaryType.Wkg]: 'WKG',
  [WorkoutSegmentPrimaryType.Slope]: 'POWER',
}

const workoutSegmentIntervalTypeMap = {
  [WorkoutSegmentIntervalType.Constant]: 'CONSTANT',
  [WorkoutSegmentIntervalType.FreeRide]: 'FREE_RIDE',
  [WorkoutSegmentIntervalType.Ramp]: 'RAMP',
}

export function normalizeWorkoutSegment(
  segment: WorkoutSegmentFragment,
): ZoneSegment {
  return {
    ...segment,
    type: workoutSegmentTypeMap[segment.type] as ZoneSegmentType,
    intervalType: workoutSegmentIntervalTypeMap[
      segment.intervalType
    ] as ZoneSegmentIntervalType,
  }
}
