import {
  WorkoutCard as SharedWorkoutCard,
  WorkoutCardDifficulty,
} from '@rouvydev/web-components/cards'
import { useTranslation } from 'react-i18next'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { WorkoutDurationType, type WorkoutFragment } from '~/model/api.ts'
import { normalizeWorkoutSegment } from '~/utils/workouts'

export function WorkoutCard(
  props: WorkoutFragment & {
    expand?: boolean
    className?: string
  },
) {
  const { t } = useTranslation(['collections'])
  const trainingEffects = useTrainingEffects(props.trainingEffects)
  const url = `${ENV.RIDERS_PORTAL_URL}/workouts/${props.id}`

  let difficulty: WorkoutCardDifficulty | undefined = undefined
  let difficultyLabel: string = ''
  const isDistance = props.durationType === WorkoutDurationType.DistanceInMeters
  const geometry =
    props?.recommendedRoute?.simplifiedGeometryPoints?.filter(Boolean) || []

  switch (props.difficulty) {
    case 'easy':
      difficulty = WorkoutCardDifficulty.EASY
      difficultyLabel = t('workout_difficulty_easy')
      break
    case 'medium':
      difficulty = WorkoutCardDifficulty.MEDIUM
      difficultyLabel = t('workout_difficulty_medium')
      break
    case 'hard':
      difficulty = WorkoutCardDifficulty.HARD
      difficultyLabel = t('workout_difficulty_hard')
      break
  }

  return (
    <SharedWorkoutCard
      workout={{
        title: props.name,
        zoneSegments: props.segments.map(normalizeWorkoutSegment),
      }}
      routeGeometry={isDistance ? geometry : []}
      link={<NormalizedLink to={url} />}
      strings={{
        completed: t('completed'),
        recommended: t('recommended'),
        difficulty: difficultyLabel,
        hours: t('hours_label'),
        minutes: t('minutes_label'),
        seconds: t('seconds_label'),
      }}
      flags={{
        completed: false,
        difficulty: difficulty,
        trainingEffects: trainingEffects,
      }}
      stats={{
        durationInSeconds: props.duration,
        coins: props.coinsForCompletion,
        tss: props.tss,
      }}
      className={props.className}
      useFixedSize
    />
  )
}

function useTrainingEffects(trainingEffects: string[]) {
  const { t } = useTranslation(['collections'])

  function translateTrainingEffect(tag: string) {
    switch (tag) {
      case 'ftp-test':
        return t('workout_training_effect_ftp_test')
      case 'vo2-max':
        return t('workout_training_effect_vo2_max')
      case 'climbing':
        return t('workout_training_effect_climbing')
      case 'sprinting':
        return t('workout_training_effect_sprinting')
      case 'cadence':
        return t('workout_training_effect_cadence')
      case 'threshold':
        return t('workout_training_effect_threshold')
      case 'recovery':
        return t('workout_training_effect_recovery')
      case 'anaerobic':
        return t('workout_training_effect_anaerobic')
      case 'strength':
        return t('workout_training_effect_strength')
      case 'race':
        return t('workout_training_effect_race')
      case 'sweet-spot-tempo':
        return t('workout_training_effect_sweet_spot_tempo')
      case 'time-trial':
        return t('workout_training_effect_time_trial')
      case 'endurance':
        return t('workout_training_effect_endurance')
      default:
        return null
    }
  }

  return trainingEffects.map(translateTrainingEffect).filter(Boolean)
}
